<template>

    <v-card variant="outlined" density="compact">
        <v-card-title class="bg-grey-lighten-3">
            <v-btn @click="showJsonDialog" class="float-right" variant="plain" density="compact">
                <v-icon>mdi-code-json</v-icon>
            </v-btn>
            <div class="d-flex justify-center">{{ evse.evse_id }}</div>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="pa-0">

            <!-- Status -->
            <div v-if="evse.deleted_at" class="error d-flex justify-center text-overline">
                <span class="text-red font-weight-bold">Removed - {{ dateFullFormat(evse.deleted_at) }}</span>
            </div>

            <span v-else class="d-flex justify-center text-overline">
                <span class="font-weight-bold ">Status: {{ evse.status }}</span>
            </span>

            <!-- Connectors -->
            <v-row v-if="evse.connectors.length > 0">
                <v-col v-for="connector in evse.connectors" :key="connector.id" class="d-flex justify-center ma-2">
                    
                    <v-icon color="accent">mdi-power-plug</v-icon>
                    {{ connector.number }}
                    <span class="pl-3">{{ parseFloat(parseInt(connector.max_electric_power || 0) / 1000).toFixed(2) }} kW</span>
                    <span class="pl-2">{{ connector.standard }}</span>
                
                    <div v-if="mode == 'advanced'">

                        <span class="d-flex justify-center text-overline">
                            <span v-if="!connector.applicable_tariffs.length > 0" class="d-flex justify-center text-overline">No Connector tariffs found</span>
                            <span v-else class="font-weight-medium">Connector Tariffs</span>
                        </span>

                        <div v-if="connector.applicable_tariffs.length"
                            v-for="tariff in connector.applicable_tariffs" :key="tariff.id"
                            variant="outlined" color="accent" size="small">
                        
                            <div class="my-1" v-for="(element, index) in tariff.elements.a" :key="`price_component-${index}`">
                    
                                <v-chip variant="outlined" color="accent" size="small" v-for="(price_component, index) in element.price_components" :key="index">
                                    <div class="pr-1" style="border-right: ridge">
                                        <v-icon size="small">mdi-tag</v-icon>
                                        {{ tariff.id }}
                                    </div>
                                    <div class="pl-1">
                                        {{ price_component.price }} {{ tariff.currency }} x kWh {{ price_component.vat ? `(VAT ${price_component.vat}%)` : ''}}
                                    </div>
                                </v-chip>

                                <v-btn variant="text" size="small" class="pl-0 ml-0" :to="`/tariffs/${tariff.id}`">
                                    <v-icon>mdi-arrow-top-right</v-icon>
                                </v-btn>

                            </div>

                        </div>
                        
                    </div>
                        
                </v-col>
            </v-row>

            <span v-else class="d-flex justify-center text-overline">No connectors found</span>
        </v-card-text>
    </v-card>

</template>

<script>
import { EventBus } from "@/event-bus"
import PriceComponentChip from "@/components/tariff/PriceComponentChip"
import JsonDialog from "@/components/JsonDialog"

    export default {
        name: "evseStructureCard",
        props: ["evse", "parentColumnSize", "mode"],
        components: {
            PriceComponentChip,
            JsonDialog
        },
        methods: {
            showJsonDialog(){
                EventBus().emit("dialog-json:open", { title: 'Evse', json: this.evse })
            }
        }
    }
</script>

<style scoped>
  .v-btn--size-small {
    height: 20px;
    min-width: 20px !important;
    padding: 0 0 0px 0px !important;
  }


</style>