<template>
    <v-card variant="outlined" class="d-flex flex-column">
        <v-card-text>
            <p class="text-subtitle-2 mb-4"><v-chip class="mr-2" color="indigo" variant="outlined" size="small">PUT</v-chip>Location</p>
            <p class="mb-4">Send information of this Location to Provider. Location, evses and connectors that belong to it will be
                sent.</p>
            <v-autocomplete 
                class="mb-0" 
                v-model="providersToSend"
                :items="showAllProviders ? providers : locationGroupProviders" 
                :rules="[v => !!v]" 
                item-text="id"
                item-value="id"
                :custom-filter="autocompleteDeepSearch"
                label="Send to"
                multiple 
                variant="outlined" 
                hide-details
            >
                <template v-slot:selection="{ props, item }">
                    <v-chip v-bind="props" size="small" variant="outlined">
                        <v-icon color="black">mdi-earth</v-icon>{{ `${item.raw.country_code}-${item.raw.party_id}` }}
                    </v-chip>
                </template>
                <template v-slot:prepend-item>
                    <v-switch 
                        v-model="showAllProviders"
                        class="pl-4" 
                        color="accent" 
                        hide-details
                        label="Providers without permissions"
                    />
                    <v-divider></v-divider>

                    <v-row class="pl-5 py-2" align="center">
                        <v-checkbox
                            :input-value="allSelected"
                            :indeterminate="!allSelected && providersToSend.length > 0"
                            :color="providersToSend.length > 0 ? 'accent' : ''" 
                            @update:modelValue="selectAll"
                            hide-details
                        >
                        </v-checkbox>    
                        <span class="pl-7">Select all</span>               
                    </v-row>
                </template>

                <template v-slot:item="{ props, item }">

                    <v-list-item v-bind="props" title>
                        <template v-slot:prepend="{ isActive }">
                            <v-list-item-action start>
                                <v-checkbox-btn :model-value="isActive" :input-value="providersToSend.includes(item.raw.id)" color="accent"/>
                            </v-list-item-action>
                        </template>

                        <v-list-item-title>
                            <v-icon color="black">mdi-earth</v-icon>
                            <span class="ml-2">{{ `${item.raw.country_code}-${item.raw.party_id}` }}</span>
                            <v-chip v-if="item.raw.role" class="ml-2" size="small" variant="outlined">{{ item.raw.role.replaceAll(',', '/') }}</v-chip>
                        </v-list-item-title>
                        
                    </v-list-item>
                </template>
            </v-autocomplete>
        </v-card-text>
        <v-card-actions class="py-0 mb-4">
            <v-btn @click="sendLocationToProvider" variant="text" block :loading="loading" color="accent">
                <v-icon start>mdi-upload</v-icon>
                PUT Location
            </v-btn>
        </v-card-actions>

        <v-dialog v-model="showReport" max-width="1000">
            <v-card v-if="report">
                <v-card-title class="accent white--text">
                    <span class="headline">
                        <v-icon class="white--text" start>mdi-file-document-alert-outline</v-icon> 
                        <span>Report</span>
                    </span>
                </v-card-title>
                <v-card-text class="py-4 pb-0">
                    <v-data-table
                        class="rounded-table"
                        :headers="[
                            {title: 'Provider', key: 'provider_id'},
                            {title: 'Success', key: 'success', align: 'center'},
                            {title: 'Error', key: 'error_message'}
                        ]"
                        :items="report"
                        :sort-by="[{ key: 'Success', order: 'desc' }]"
                        density="compact"
                    >
                    <template v-slot:item.provider_id="{ item }">
                        <v-row :set="provider = providers.find(p => p.id == item.provider_id) || locationGroupProviders.find(p => p.id == item.provider_id)" class="ma-0">
                            <template v-if="provider">
                                <v-icon color="black">mdi-earth</v-icon>
                                <span class="ml-2">{{ `${provider.country_code}-${provider.party_id}` }}</span>
                            </template>
                            <template v-else>{{ item.provider_id }}</template>
                        </v-row>
                    </template>
                    <template v-slot:item.success="{ item }">
                        <v-icon v-if="item.success" color="success">mdi-check-circle</v-icon>
                        <v-icon v-else color="error">mdi-close-circle</v-icon>
                    </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn @click="showReport = false" variant="text">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
export default {
    name: "send-location-tool",
    props: {
        locationId: {
            type: Number,
            required: true
        },
        locationGroupProviders: {
            type: Array,
            required: true
        },
        providers: {
            type: Array,
            default: []
        }
    },
    inject: ['$api'],
    data() {
        return {
            loading: false,
            showAllProviders: false,
            providersToSend: [],
            showReport: false,
            report: null
        }
    },
    computed:{
        allSelected() {
            return this.showAllProviders 
                ? this.providersToSend.length == this.providers.length 
                : this.providersToSend.length == this.locationGroupProviders.length
        }
    },
    watch:{
        showReport(show) {
            if (!show) { this.report = null }
        }
    },
    methods: {
        selectAll(value) {
            if (value) {
                if (this.showAllProviders) {
                    this.providersToSend = this.providers.map(p => p.id)
                } else {
                    this.providersToSend = this.locationGroupProviders.map(p => p.id)
                }
            } else {
                this.providersToSend = []
            }
        },
        sendLocationToProvider() {
            if (!this.providersToSend.length) { this.showError('Provider must be selected to send Location'); return }
            
            const data = { providerIds: this.providersToSend }
            
            this.loading = true
            this.$api.post(`location/${this.locationId}/send-to/providers`, data)
                .then((resp) => this.showCustomAlert('success', 'Location has been sent successfully.', { clickFn: () =>  this.openReport(resp.data), text:'View Report', icon:'mdi-arrow-top-right' }))
                .catch(({ response }) => this.showCustomAlert('error', 'There was an error sending location.', { clickFn: () =>  this.openReport(response.data), text:'View Report', icon:'mdi-arrow-top-right' }))
                .then(() => { this.loading = false })
        },
        openReport(report){
            this.report = report
            this.showReport = true
        }
    }
}
</script>

<style scoped>
    /* Fix vuetify visual bug */
    :deep(.v-list-item--highlighted::before){
        opacity: 0 !important;
    }
    /* Fix vuetify visual bug */
    :deep(.v-list-item--highlighted.v-list-item--active::before){
        opacity: 0.12 !important;
    }
</style>