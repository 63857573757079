import store from '@/store'

// https://date-fns.org/docs/Getting-Started
import { parseISO, format } from "date-fns"

export default {
    methods: {
        
        setTitle(str) {
            document.title = `OCPI: ${str}`
        },

        showError(error){

            let text = ''

            switch(typeof error){
                case 'object':

                    if( error.response && error.response.data && error.response.data.error ){
                        text = error.response.data.error.message
                        console.error(text, error.response.data.error)
                    } else {
                        if( error.response && typeof error.response.data === 'string'){
                            text = error.response.data
                        } else if( error.response && typeof error.response.data === 'object' ) {
                            text = JSON.stringify(error.response.data)
                        } else {
                            text = error.message
                        }
                        console.log(text, error.response)
                    }

                break;
                default:

                    text = error
                    console.log(error)
                    
                break;
            }

            store.dispatch('alert', {text, type: 'error'})

            return false

        },

        showSuccess(text){
            store.dispatch('alert', {text, type: 'success'})
            return true
        },

        showCustomAlert(type, text, button){
            store.dispatch('alert', {text, button, type})
            return true
        },

        openMaps(coordinates){
            window.open(`https://www.google.com/maps/search/?api=1&query=${coordinates.latitude},${coordinates.longitude}`)
        },

        goCtrlClick(event,param,link) {
            if (event.ctrlKey === true) {
                let routeData = this.$router.resolve({path: `/${link}/${param}`})
                window.open(routeData.href, '_blank')
            } else {
                this.$router.push({path: `/${link}/${param}`})
            };
        },

        goTo(path){
            this.$router.push(path)
        },

        //Dynamic route params, for correct implementation set all filters with 'selected' prefix. E.g. this.selectedType
        routeFilters(params) {
            for (const param in params) {
                //Set param first letter to uppercase
                const paramCapitalized = param.charAt(0).toUpperCase() + param.slice(1)

                //If this.$route.query[param] can be a number and its not "search" filter (it expects a string), then convert it to number
                if (!isNaN(this.$route.query[param]) && param !== 'search') {
                    this[`selected${paramCapitalized}`] = Number(this.$route.query[param])
                } else {
                    this[`selected${paramCapitalized}`] = this.$route.query[param]
                }
            }
        },

        //urlPush method will push url query params without reloading the page
        urlPush(filterValue, selectedVariable) {
            //array of undesiered vue-router query params
            const vueRouterQueryParams = ['back', 'current', 'replaced', 'position', 'key', 'scroll', 'forward']

            //get current window history state + new filter values
            const newHistoryState = Object.assign({}, window.history.state, {[filterValue]: selectedVariable})

            //filter newHistoryState to remove values from vueRouterQueryParams , "undefined" values and null values
            const newHistoryStateFiltered = Object.keys(newHistoryState).reduce((obj, key) => {
                if (newHistoryState[key] !== undefined && newHistoryState[key] !== null && !vueRouterQueryParams.includes(key)) {
                    obj[key] = newHistoryState[key]
                }
                return obj
            }, {})

            if( Object.keys(newHistoryStateFiltered).length === 0 ){
                return
            }

            //Push new window history state
            window.history.pushState(
                newHistoryState,
                null,
                `#/${this.$route.path.slice(1)}?` +
                Object.keys(newHistoryStateFiltered).map
                    (key => `${key}=${newHistoryStateFiltered[key]}`)
                    .join('&')
            )

        },

        /**
         * Genera un documento y los descarga
         * 
         * @param {*} content 
         * @param {*} filename 
         * @param {*} contentType 
         */
        downloadBlob(content, filename, contentType) {

            // Create a blob
            var blob = new Blob(["\ufeff", content], { type: contentType })
            var url = URL.createObjectURL(blob)

            // Create a link to download it
            var pom = document.createElement('a')
            pom.href = url
            pom.setAttribute('download', filename)
            pom.click()
            
        },

        /**
         * Overrides default filter of v-data-table to search entire object instead of only on fields used in table columns.
         * https://vuetifyjs.com/en/api/v-data-table/#props-custom-filter
         * 
         */
        dataTableDeepSearch(value, search, item) {
            search = search.toString().toLowerCase()

            const hasValue = (object, value) => {
                return Object.keys(object).some(j => {
                    if(object[j] == null || object[j] == undefined){ return false }
                    if (object[j] instanceof Object) { return hasValue(object[j], value) }
                    return object[j].toString().toLowerCase().includes(value)
                })
            }

            return hasValue(item, search)
        },

        /**
         * Overrides default filter of v-autocomplete to search entire object.
         * https://vuetifyjs.com/en/api/v-autocomplete/#props-custom-filter
         * 
         */
        autocompleteDeepSearch(value, search, item) {
            search = search.toString().toLowerCase();
        
            const hasValue = (object, value) => {
                /**
                 *  Concatened values is useful when object is a provider 
                 *  ej: {country_code: 'ES', party_id: 'XXX'} and we want to search by 'es-xxx'
                */
                const concatenatedValues = Object.values(object).filter(v => v != null && v != undefined).join('-').toLowerCase()
                if (concatenatedValues.includes(value)) {
                    return true
                }
                return Object.keys(object).some(j => {
                    if (object[j] == null || object[j] == undefined) { return false }
                    if (object[j] instanceof Object) { return hasValue(object[j], value) }
                    return object[j].toString().toLowerCase().includes(value)
                })
            }
        
            return hasValue(item, search)
        },

        clipboard(text){
            const vm = this
            navigator.clipboard.writeText(text)
                .then(
                    function (e) {
                        vm.showSuccess('Text copied')
                    }, 
                    function (e) {
                        vm.showError('Can not copy')
                    }
                )
        },

        monthFormat(isoFormat){
            if( !isoFormat ){ return '' }
            return format(parseISO(isoFormat), "yyyy-MM")
        },
        
        dateFormat(isoFormat){
            if( !isoFormat ){ return '' }
            return format(parseISO(isoFormat), "yyyy-MM-dd")
        },

        dateTimeFormat(isoFormat){
            if( !isoFormat ){ return '' }
            return format(parseISO(isoFormat), "HH:mm:ss")
        },

        dateCustomFormat(isoFormat, inputFormat){
            if( !isoFormat ){ return '' }
            return format(parseISO(isoFormat), inputFormat)
        },

        dateFullFormat(isoFormat){
            if( !isoFormat ){ return '' }
            /**
             * Workarround para las fechas created_at y updated_at. 
             * La DB las devuelve en formato local del servidor (UTC+0) sin indicador de zona horaria "TZ". 
             * 
             * Ej: "2024-09-14 17:00:00". Realmente es "2024-09-14T17:00:00Z".
             * Al enviar al navegador se ve como "2024-09-14 17:00:00 +02" y lo correcto es "2024-09-14 19:00:00 +02"
            */
            if( !isoFormat.includes('T') && !isoFormat.includes('Z') ){
                isoFormat = isoFormat.replace(' ', 'T') + 'Z' 
            }
            return format(parseISO(isoFormat), "yyyy-MM-dd HH:mm:ss")
        },

        copyToClipboard(text) {
            navigator.clipboard.writeText(text)
        },

        /**
         * OCPI total_time format
        */
        hoursToTimeTextFormat(input){
            if( !input ){ return '' }

            var hours = Number(input)
            var rhours = Math.floor(hours)
            var minutes = (hours - rhours) * 60
            var rminutes = Math.round(minutes)
            
            return `${rhours} hour(s) ${rminutes} minute(s)`
        }

    }

}