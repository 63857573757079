<template>
    <v-container fluid>
        <AppBar title="Providers" icon="mdi-earth" />

        <!-- Table filters -->
        <v-row class="filer-row">
            <v-col cols="12" md="2">
                <SearchTextField
                    v-model="selectedSearch"
                    :searchFunction="onFilter"
                />   
            </v-col>

            <v-col cols="12" md="2">
                <v-autocomplete
                    v-model="selectedType"
                    variant="outlined"
                    class="filter-field accent"
                    :items="[
                        { title: 'Provider', value: 'provider' },
                        { title: 'Hub', value: 'hub' },
                    ]"
                    label="Type"
                    clearable
                    hide-details
                ></v-autocomplete>
                
            </v-col>

            <v-col cols="12" md="2">
                <v-autocomplete
                    v-model="selectedRole"
                    variant="outlined"
                    class="filter-field accent"
                    :items="[
                        { title: 'CPO', value: 'CPO' },
                        { title: 'EMSP', value: 'EMSP' },
                        { title: 'NSP', value: 'NSP' },
                    ]"
                    label="Role"
                    multiple
                    clearable
                    hide-details
                ></v-autocomplete>
                
            </v-col>

            <SearchButton
                :loading="tableLoading"
                :searchFunction="onFilter"
            />
            
            <v-spacer/>

            <v-btn
                class="mr-2"
                to="/provider/create"
                color="accent"
                variant="outlined"
            >
                <v-icon start>mdi-plus</v-icon> Create
            </v-btn>

            <v-btn
                variant="outlined"
                href="https://wenea.atlassian.net/wiki/spaces/WIKI/pages/561774605/Providers" 
                target="_blank"
            ><v-icon start>mdi-open-in-new</v-icon> Confluence List</v-btn>
        </v-row>

        <v-data-table
            :headers="[
                { key: 'linkto', sortable: false, cellClass: 'td-linkto', width: '5px' },
                { title: 'Id.', key: 'id' },
                { title: 'Provider', key: 'provider' },
                { title: 'Name', key: 'name' },
                { title: 'Type', key: 'user.type' },
                { title: 'OCPI Ver.', key: 'user.ocpi_version' },
                { title: 'Roles', key: 'role' },
                { title: 'Flags', key: 'flags' },
                { title: 'Updated', key: 'updated_at' },
            ]"
            :items="filteredProviders"
            :loading="tableLoading"
            :search="tableSearch"
            :custom-filter="dataTableDeepSearch"
            disable-pagination
            hide-default-footer
            :items-per-page="-1"
            :fixed-header="true"
            class="accent-table rounded-table"
            density="compact"
        >
            <template v-slot:item.linkto="{ item }">
                <v-btn variant="plain" density="compact" size="small" slim :to="`/provider/${item.id}`">
                    <v-icon start >mdi-arrow-top-right</v-icon>
                </v-btn>
            </template>
            <template v-slot:item.provider="{ item }">
                <ProviderName
                    :provider="{ id: item.id, country_code: item.country_code , party_id: item.party_id }"
                    extra="no-arrow"
                />
            </template>
            <template v-slot:item.name="{ item }">

                <span v-if="item.company">
                    {{ item.company.name }}
                </span>

                <span v-else-if="item.user.company">
                    {{ item.user.company.name }}
                </span>

                <span v-else>
                    {{ item.user.username }}
                </span>
            </template>
            <template v-slot:item.user.type="{ item }">
                <v-chip class="text-chip text-uppercase"
                    size="small"
                    label
                    variant="outlined"
                >
                {{ item.user.type === 'provider' ? 'direct' : item.user.type }}
                </v-chip>
            </template>
            <template v-slot:item.user.ocpi_version="{ item }">
                <v-chip
                    v-if="item.user.ocpi_version"
                    color="accent"
                    variant="outlined"
                    size="small"
                >
                    {{ item.user.ocpi_version }}
                </v-chip>
            </template>
            <template v-slot:item.role="{ item }">
                <v-chip
                    v-for="(role, index) of splitRoles(item.role)"
                    :key="`${item.id}_${role}`"
                    :class="`${index > 0 ? 'ml-1' : ''}`"
                    color="accent"
                    variant="outlined"
                    size="small"
                >
                    {{ role }}
                </v-chip>
            </template>
            <template v-slot:item.flags="{ item }">
                <v-chip
                    v-for="(flag, index) of providerFlags(item)"
                    :key="`${item.id}_${flag}`"
                    :class="`${index > 0 ? 'ml-1 my-1' : ''}`"
                    color="accent"
                    variant="outlined"
                    size="small"
                >
                    {{ flag }}
                </v-chip>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import { EventBus } from "@/event-bus"
import AppBar from "@/components/AppBar"
import _ from "lodash"
import ProviderName from "@/components/provider/ProviderName"
import SearchTextField from "@/components/ui-components/SearchTextField.vue"
import SearchButton from "@/components/ui-components/SearchButton.vue"

export default {
    name: "providers",
    components: {
        AppBar,
        ProviderName,
        SearchTextField,
        SearchButton,
    },
    data() {
        return {
            providers: [],
            filteredProviders: [],
            tableLoading: false,
            selectedSearch: null,
            selectedType: null,
            selectedRole: [],
            tableSearch: null,
        };
    },
    inject: ['$api'],
    mounted() {
        EventBus().on("dialog-provider:close", (data) => {
            this.loadList()
        })
    },
    watch: {
        //Necessary to update the filters when the user uses the browser back/forward button also to update filters when the user refresh the page
        '$route.query':{ 
            handler(params) {
                this.routeFilters(params)
                this.onFilter()
            },
            immediate: true
        },          
    },
    methods: {
        async loadList() {

            this.tableLoading = true

            this.providers = await this.$api.get("/providers-external")
                .then((res) => {
                    res.data.map((p) => {
                        p.via = `${p.user.company?.name} (${p.user.type})`
                        p.provider_name = `${p.country_code}-${p.party_id}`
                    })
                    return res.data
                })
                .catch(this.showError)

            this.tableLoading = false

        },
        splitRoles(roles) {
            return roles ? roles.split(",") : ""
        },
        providerFlags(item) {
            const flags = {
                'flag_real_time_auth': 'Send RTA',
                'flag_evse_status': 'Send Evse Status',
                'flag_fetch_cdrs_auto': 'Fetch CDRs',
                'flag_fetch_locations_auto': 'Fetch Locations',
                'flag_fetch_tariffs_auto': 'Fetch Tariffs',
                'flag_fetch_tokens_auto': 'Fetch Tokens',
                'flag_put_locations_auto': 'Put Locations',
            }
            return Object.keys(flags).filter(flag => item[flag]).map(flag => flags[flag])
        },
        async onFilter(){
            if(this.tableLoading) return
            this.tableSearch = this.selectedSearch
            this.urlPush('search', this.selectedSearch)
            this.urlPush('type', this.selectedType)

            await this.loadList()
            this.filteredProviders = this.providers

            if(this.selectedType){
                this.filteredProviders = this.providers.filter(p => p.user.type === this.selectedType)
            }

            if(this.selectedRole.length > 0){
                /**
                 * TM-2835
                 * If there is only one role selected, search providers who have that role AS ONE of their roles
                 * Otherwise if multiple roles are selected, only search for providers whose roles are EXACTLY the ones selected
                */ 
                if(this.selectedRole.length == 1){
                    this.filteredProviders = this.filteredProviders.filter(p => p.role.includes(this.selectedRole))
                }else{
                    //Roles can be unsorted. Ej: for CPO,EMSP or EMSP,CPO. shown providers must be the same
                    this.filteredProviders = this.filteredProviders.filter(p => {
                        const allRolesMatch = this.selectedRole.every(role => p.role.includes(role))
                        const exactMatch = this.splitRoles(p.role).length == this.selectedRole.length
                        return allRolesMatch && exactMatch
                    })
                }
            }

        }
    },
};
</script>