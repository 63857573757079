<template>
    <v-container fluid>
        <div v-if="connection">

            <AppBar
                :breadcrumbs="[{ text: 'Connections', to: '/connections' }, { text: name, icon: 'mdi-vector-polyline' }]" />

            <v-card variant="outlined">
                <v-card-title class="bg-accent-lighten1 pl-8">{{name}}</v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="2">
                            <v-list-item lines="two">
                                    <v-list-item-title>{{ connection.id || "-" }}</v-list-item-title>
                                    <v-list-item-subtitle>Id</v-list-item-subtitle>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-list-item lines="two">
                                    <v-list-item-title>{{ connection.username || "-" }}</v-list-item-title>
                                    <v-list-item-subtitle>Username</v-list-item-subtitle>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-list-item lines="two">
                                    <v-list-item-title>
                                        <v-chip
                                            v-if="connection.type"
                                            color="accent"
                                            variant="outlined"
                                            size="small"
                                        >
                                            {{ connection.type == 'provider' ? 'DIRECT' : connection.type.toUpperCase() }}
                                        </v-chip>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Type</v-list-item-subtitle>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-list-item lines="two">
                                    <v-list-item-title>
                                        <v-chip
                                            v-if="connection.ocpi_version"
                                            color="accent"
                                            variant="outlined"
                                            size="small"
                                        >
                                        {{ connection.ocpi_version || "-" }}
                                        </v-chip>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>OCPI Version</v-list-item-subtitle>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-list-item lines="two">
                                    <v-list-item-title>
                                        <StatusChip v-if="connection.status" :status="connection.status" />
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Status</v-list-item-subtitle>
                            </v-list-item>
                        </v-col>
                        

                        <v-col cols="12" sm="6">
                            <v-list-item lines="two">
                                    <div> 
                                        <v-btn 
                                            size="x-small"
                                            variant="plain"
                                            icon="mdi-content-copy"
                                            @click="() => { copyToClipboard(weneaVersionsEndpoint); showSuccess('URL copied to clipboard') }"
                                        />
                                        {{weneaVersionsEndpoint}}
                                    </div>
                                    <v-list-item-subtitle>Wenea versions endpoint</v-list-item-subtitle>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-list-item lines="two">
                                    <v-list-item-title>{{ connection.email || "-" }}</v-list-item-title>
                                    <v-list-item-subtitle>e-mail</v-list-item-subtitle>
                            </v-list-item>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-list-item lines="two">
                                    <v-list-item-title>{{ connection.ocpi_url_versions || "-" }}</v-list-item-title>
                                    <v-list-item-subtitle>Versions URL</v-list-item-subtitle>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-list-item lines="two">
                                    <v-list-item-title>{{ connection.ocpi_url_endpoints || "-" }}</v-list-item-title>
                                    <v-list-item-subtitle>Endpoints URL</v-list-item-subtitle>
                            </v-list-item>
                        </v-col>

                    </v-row>
                </v-card-text>

                <!-- Company -->
                <CompanyPanel v-if="company && company.id" :company="company" />
            
            </v-card>

            <v-toolbar variant="flat" color="white" class="my-1 buttons-toolbar">

                <v-spacer />

                <v-btn 
                    v-if="!company || !company.id" 
                    :to="`/company/create?user_id=${connection.id}`" 
                    color="accent"
                    class="mr-2 ml-2"
                    variant="outlined"
                    prepend-icon="mdi-plus">
                        
                        Create company
                </v-btn>

                <v-btn
                    v-if="connection.type == 'hub' && providers.find(p => p.role.includes('HUB'))"
                    :to="`/provider/${providers.find(p => p.role.includes('HUB')).id}`"
                    class="mr-2 ml-2"
                    variant="outlined"
                    color="accent"
                    prepend-icon="mdi-earth"
                >
                    Provider
                </v-btn>

                <v-btn
                    v-if="connection.type == 'provider' && providers.length > 0"
                    :to="`/provider/${providers[0].id}`"
                    class="mr-2 ml-2"
                    variant="outlined"
                    color="accent"
                    prepend-icon="mdi-earth"
                >
                    Provider
                </v-btn>

                <v-btn
                    :to="`/connection/${connection.id}/edit`"
                    variant="flat"
                    color="accent"
                    prepend-icon="mdi-pencil"
                >
                    Modify
                </v-btn>

            </v-toolbar>

            <div class="my-2" v-if="connection">
                <div class="float-right">
                    
                </div>
            </div>

            <!-- Operaciones -->
            <h2 class="mt-6 mb-2 ml-2">
                <v-icon start size="x-small" color='accent'>mdi-wrench</v-icon>Operations
            </h2>
            <v-row>

                <v-col cols="12" sm="6" md="4">
                    <v-card variant="outlined" class="fill-height">
                        <v-card-text>
                            <p>Obtain the connection's credentials or start registration to create new credentials.</p>
                        </v-card-text>
                        <v-card-actions>
                            <v-row class="px-3 pb-3">
                                <v-btn class="mb-2" @click="fetchCurrentCredentials" text block color='accent'>
                                    <v-icon start>mdi-shield-lock</v-icon>
                                    Get current
                                </v-btn>
                                <v-btn @click="showDialogProviderFetchCredetnials" text block color='accent'>
                                    <v-icon start>mdi-shield-lock</v-icon>
                                    Start registration
                                </v-btn>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-col>

                <v-col cols="12" sm="6" md="4" v-if="connection.type == 'hub'">
                    <v-card variant="outlined" class="fill-height">
                        <v-card-text>
                            <p>Obtain the list of known ClientInfo objects.</p>
                        </v-card-text>
                        <v-card-actions class="fill-height">
                            <v-btn class="mb-sm-5 mb-lg-4 mb-xl-10" @click="fetchHubClientInfo" text block color='accent'>
                                <v-icon start>mdi-shield-lock</v-icon>
                                Get hub info
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>

            </v-row>

            <v-expansion-panels v-if="connection" class="accent-expansion-panels depressed-expansion-panels mt-4" focusable multiple elevation="0">
                <!-- Credentials (user-type = provider) -->
                <v-expansion-panel>
                    <v-expansion-panel-title>
                        <v-icon start color='accent' size="small">mdi-shield-lock</v-icon>
                        Credentials
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <div>
                            <h3 class="pa-4 pb-0">
                                <v-chip variant="flat" class="subtitle-1 bg-secondary-chip">Wenea</v-chip>
                                <v-icon color="secondary">mdi-arrow-right</v-icon>
                                <span v-if="connection.company">{{ connection.company.name }}</span>
                                <span v-else>{{ connection.username }}</span>
                            </h3>

                            <v-row>
                                <v-col cols="2" >
                                    <v-list-item lines="two">
                                            <v-list-item-title>OCPI Version</v-list-item-title>
                                            <v-list-item-subtitle>{{ connection.ocpi_version }}</v-list-item-subtitle>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="2">
                                    <v-list-item lines="two">
                                            <v-list-item-title>
                                                <v-btn 
                                                    v-if="connection.ocpi_token_a" 
                                                    @click="showDialogToken(connection.ocpi_token_a)" 
                                                    color='accent'
                                                    variant="text"
                                                    prepend-icon="mdi-key"
                                                >
                                                    Token A
                                                </v-btn>
                                                <span v-else>Token A</span>
                                            </v-list-item-title>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="2">
                                    <v-list-item lines="two">
                                            <v-list-item-title>
                                                <v-btn 
                                                    v-if="connection.ocpi_token_c" 
                                                    @click="showDialogToken(connection.ocpi_token_c)"
                                                    color='accent'
                                                    variant="text"
                                                    prepend-icon="mdi-key"
                                                >
                                                    Token C
                                                </v-btn>
                                                <span v-else>Token C</span>
                                            </v-list-item-title>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </div>

                        <v-divider />

                        <v-row class="ma-0 px-4 align-center">
                            <h3>
                                <span v-if="connection.company">{{ connection.company.name }}</span>
                                <span v-else>{{ connection.username }}</span>
                                <v-icon color="secondary">mdi-arrow-right</v-icon>
                                <v-chip variant="flat" class="subtitle-1 bg-secondary-chip">Wenea</v-chip>
                            </h3>
                            <v-spacer />

                            <!-- Show only non revoked tokens -->
                            <v-checkbox
                                class="mr-4"
                                label="Show revoked"
                                color="accent"
                                hide-details
                                v-model="showRevoked"
                            />

                            <v-btn
                                color="accent"
                                variant="outlined"
                                text
                                size="small"
                                @click="renewToken"
                                >
                                <v-icon start size="small">mdi-autorenew</v-icon>Renew Token
                            </v-btn>
                        </v-row>

                        <div>
                            <v-data-table 
                                class="accent-table"
                                :headers="[
                                    { title: 'Created at', key: 'created_at' },
                                    { title: 'Token', key: 'token' },
                                    { title: 'Revoked', key: 'is_revoked', align: 'center' },
                                    { title: '', key: 'action', sortable: false }
                                ]"
                                :sort-by="[{ key: 'is_revoked', order: 'asc' }]"
                                :items="filteredTokens"
                                no-data-text="This connection does not have any defined Tokens"
                                disable-pagination 
                                hide-default-footer 
                                :items-per-page="-1"
                                density="compact"
                            >
                                <template v-slot:item.token="{ item }">
                                    <v-btn 
                                        @click="showDialogToken(item.token)"
                                        color='accent'
                                        variant="text"
                                        prepend-icon="mdi-key"
                                        >
                                        Show
                                    </v-btn>
                                </template>
                                <template v-slot:item.is_revoked="{ item }">
                                    <v-icon v-if="item.is_revoked" color="error">mdi-close-circle</v-icon>
                                    <v-icon v-else color="success">mdi-check-circle</v-icon>
                                </template>
                                <template v-slot:item.action="{ item }">

                                    <!-- UnRevoke Token -->
                                    <v-tooltip bottom v-if="item.is_revoked">
                                        <template v-slot:activator="{ props }">
                                            <v-icon 
                                                v-bind="props"
                                                class="mx-2" 
                                                @click="unRevokeToken(item)"
                                            >
                                                mdi-key-plus
                                            </v-icon>
                                        </template>
                                        <span>Set token as unrevoked</span>
                                    </v-tooltip>

                                    <!-- Revoke Token -->
                                    <v-tooltip bottom v-else>
                                        <template v-slot:activator="{ props }">
                                            <v-icon
                                                v-bind="props"
                                                class="mx-2" 
                                                @click="revokeToken(item)"
                                            >
                                                mdi-key-remove
                                            </v-icon>
                                        </template>
                                        <span>Set token as revoked</span>
                                    </v-tooltip>
                                    
                                    <!-- Delete Token -->
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ props }">
                                            <v-icon
                                                v-bind="props"
                                                class="mx-2"
                                                @click="removeToken(item)"
                                            >
                                                mdi-delete
                                            </v-icon>
                                        </template>
                                        <span>Delete Token</span>
                                    </v-tooltip>
                                    
                                </template>
                            </v-data-table>
                        </div>

                    </v-expansion-panel-text>
                </v-expansion-panel>

                <!-- Versions -->
                <v-expansion-panel>
                    <v-expansion-panel-title>
                        <v-icon start color='accent' size="small">mdi-numeric</v-icon>Versions
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <v-toolbar class="px-4" color="white">
                            <v-spacer></v-spacer>
                            <v-btn 
                                color="accent"
                                variant="outlined" 
                                size="small"
                                @click="fetchVersions" 
                                append-icon="mdi-arrow-down-bold"
                            >
                                Check versions
                            </v-btn>
                        </v-toolbar>
                        <v-divider />

                        <v-data-table 
                            class="accent-table"
                            :headers="[
                                { title: 'Version', key: 'version' },
                                { title: 'URL', key: 'url' }
                            ]"
                            :items="versions" 
                            disable-sort
                            disable-pagination
                            hide-default-footer
                            :items-per-page="-1"
                            density="compact"
                        ></v-data-table>
                    </v-expansion-panel-text>
                </v-expansion-panel>

                <!-- Endpoints -->
                <v-expansion-panel>
                    <v-expansion-panel-title>
                        <v-icon start color='accent' size="small">mdi-link-box</v-icon>Endpoints
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <v-toolbar class="px-4" color="white">
                            <v-spacer></v-spacer>
                            <v-btn 
                                color="accent"
                                variant="outlined"
                                size="small"
                                @click="fetchEndpoints" 
                                append-icon="mdi-arrow-down-bold"
                            >
                                Check endpoints
                            </v-btn>
                        </v-toolbar>
                        <v-divider />

                        <v-data-table 
                            class="accent-table"
                            :headers="[
                                { title: 'Module', key: 'identifier' },
                                { title: 'Role', key: 'role' },
                                { title: 'URL', key: 'url' }
                            ]" 
                            :items="endpoints" 
                            disable-sort 
                            disable-pagination 
                            hide-default-footer 
                            :items-per-page="-1"
                            density="compact"
                        ></v-data-table>
                    </v-expansion-panel-text>
                </v-expansion-panel>

                <!-- Providers -->
                <v-expansion-panel>
                    <v-expansion-panel-title>
                        <v-icon start color='accent' size="small">mdi-earth</v-icon>Providers
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <v-toolbar class="py-1 px-4" color="white">
                            <v-spacer></v-spacer>
                                <v-btn 
                                    v-if="connection.type == 'hub'"
                                    class="mr-4"
                                    @click="checkProviders()" 
                                    :loading="fetchingProviders"
                                    color="accent"
                                    size="small"
                                    variant="outlined"
                                    append-icon="mdi-arrow-down-bold"
                                >
                                    Get providers
                                </v-btn>

                                <SearchTextField
                                    max-width="215px"
                                    v-model="tableProvidersSearch"
                                    :autofocus="false"
                                />
                        </v-toolbar>
                        <v-divider />

                        <v-data-table 
                            class="accent-table"
                            :headers="[
                                { title: 'Provider', key: 'provider' },
                                { title: 'Role', key: 'role' },
                                { title: 'Last updated', key: 'last_updated' }
                            ]" 
                            :search="tableProvidersSearch"
                            :custom-filter="dataTableDeepSearch"
                            :items="providers"
                            disable-pagination
                            hide-default-footer
                            disable-sort
                            :items-per-page="-1"
                            density="compact"
                        >
                            <template v-slot:item.provider="{ item }">
                                <ProviderName
                                    :provider="{ id: item.id, country_code: item.country_code , party_id: item.party_id }"
                                />
                            </template>
                        </v-data-table>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>

        </div>

        <UserFetchCredentials />
        <TokenDialog />
        <HubClientInfoDialog />

    </v-container>
</template>

<script>
import { EventBus } from "@/event-bus"
import AppBar from "@/components/AppBar"
import HubClientInfoDialog from "@/components/connection/HubClientInfoDialog.vue"
import SearchTextField from "@/components/ui-components/SearchTextField.vue"
import UserFetchCredentials from "@/components/UserFetchCredentials"
import TokenDialog from "@/components/connection/TokenDialog.vue"
import StatusChip from "@/components/connection/StatusChip"
import ProviderName from "@/components/provider/ProviderName"
import CompanyPanel from "@/components/company/CompanyPanel"

export default {
    name: "providers",
    components: {
        AppBar,
        UserFetchCredentials,
        SearchTextField,
        TokenDialog,
        StatusChip,
        ProviderName,
        CompanyPanel,
        HubClientInfoDialog
    },
    inject: ['$api'],
    data() {
        return {
            connection: null,
            tokens: [],
            filteredTokens: [],
            showRevoked: false,
            loadingProviders: false,
            company: {},
            providers: [],
            tableProvidersSearch: "",
            versions: [],
            endpoints: [],

            fetchingProviders: false,

            weneaVersionsEndpoint: process.env.VUE_APP_WENEA_CREDENTIALS_URL + '/versions'
        }
    },
    mounted() {
        this.loadData()

        EventBus().on("dialog-user-fetch-credentials:close", (res) => {
            this.loadData()
        })
    },
    computed: {
        name(){
            return this.connection?.company?.name || this.connection?.username
        },
    },
    watch: {
        showRevoked() {
            if(this.showRevoked) {
                this.filteredTokens = this.tokens
            } else {
                this.filteredTokens = this.tokens.filter(t => !t.is_revoked)
            }
        }
    },
    methods: {
        async loadData() {

            let id = this.$route.params.id
            if (!id) { return }

            try {

                let response = await this.$api.get(`/connections/${id}`)
                this.connection = response.data
                this.endpoints = response.data.endpoints
                this.tokens = response.data.tokens
                this.filteredTokens = this.tokens.filter(t => !t.is_revoked)
                this.company = response.data.company
                
                this.showRevoked = false
                this.loadingProviders = true

                this.providers = await this.$api
                    .get(`/connections/${id}/providers`)
                    .then(resp => resp.data)
                    .catch(this.showError)

                this.loadingProviders = false

            } catch (e) {
                this.showError(e)
            }

        },
        showDialogProviderFetchCredetnials() {
            EventBus().emit("dialog-provider-fetch-credentials:open", this.connection)
        },
        showDialogToken(token) {
            EventBus().emit("dialog-show-token:open", token)
        },
        async fetchVersions() {
            this.versions = await this.$api
                .get(`user/${this.connection.id}/fetch-versions`)
                .then(resp => resp.data)
                .catch(this.showError)
        },
        async fetchEndpoints() {
            this.endpoints = await this.$api
                .get(`/user/${this.connection.id}/fetch-endpoints`)
                .then(resp => resp.data.endpoints)
                .catch(this.showError)
        },
        async checkProviders() {
            this.fetchingProviders = true
            await this.$api
                .get(`/hub/${this.connection.id}/check-providers`)
                .then(resp => {
                    this.showSuccess('Providers have been updated successfully')
                    this.providers = resp.data
                })
                .catch(this.showError)
            this.fetchingProviders = false
        },
        fetchHubClientInfo(){
            this.$api.get(`/hub/${this.connection.id}/client-info`)
                .then(resp => EventBus().emit(
                    "dialog-hub-client-info:open",
                    { title: 'Hub Client Info', json: resp.data }
                ))
                .catch(this.showError)
        },
        revokeToken(item){
            this.$api
                .patch(`/user/${this.connection.id}/revoke-token/${item.id}`)
                .then(() => this.filteredTokens.map(t => {
                    if(t.id == item.id) t.is_revoked = true
                }))
                .catch(this.showError)
        },
        unRevokeToken(item){
            this.$api
                .patch(`/user/${this.connection.id}/unrevoke-token/${item.id}`)
                .then(() => this.filteredTokens.map(t => {
                    if(t.id == item.id) t.is_revoked = false
                }))
                .catch(this.showError)
        },
        removeToken(item) {
            this.$api
                .delete(`/provider/token/${item.id}`)
                .then(() => this.loadData())
                .catch(this.showError)
        },
        renewToken() {

            const confirmed = window.confirm('When token is renewed, all other tokens are revoked. Do you want to continue?')
            if(!confirmed){ return }

            this.$api.patch(`/user/${this.connection.id}/renew-token`)
                .then(() => {
                    this.showSuccess('Token renewed successfully')
                    this.loadData()
                })
                .catch(this.showError)
        },
        fetchCurrentCredentials() {
            this.$api.get(`user/${this.connection.id}/fetch-credentials`)
                .then(() => this.showSuccess("Query done correctly"))
                .catch(this.showError)
        }
    },
};
</script>