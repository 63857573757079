<template>
    <v-container fluid>
    <div>

        <AppBar :breadcrumbs="[{ text: 'Settings', icon: 'mdi-cog' }]"/>
        <div :loading="loading"></div>
        <v-progress-linear v-if="loading" indeterminate></v-progress-linear>

        <v-card variant="outlined" class="mb-4">
            <v-list-item-title class="ml-4 mt-2">
                <h2 class="d-flex align-center">
                    <v-icon start color="accent" size="x-small">mdi-text-box-outline</v-icon>
                    Log level
                </h2>
            </v-list-item-title>
            <v-card-text>
                <v-row class="filer-row">
                    <v-col>
                        <LogLevelSelect
                            v-model="settings.workerLogLevel"
                            label="Worker log level"
                            @update:modelValue="save('workerLogLevel')"
                        />
                    </v-col>
                    <v-col>
                        <LogLevelSelect
                            v-model="settings.cpoLogLevel"
                            label="CPO log level"
                            isAdonis6
                            :disabled="settings.cpoLogLevel == null"
                            @update:modelValue="save('cpoLogLevel')"
                        />
                    </v-col>
                    <v-col>
                        <LogLevelSelect
                            v-model="settings.emspLogLevel"
                            label="EMSP log level"
                            isAdonis6
                            :disabled="settings.emspLogLevel == null"
                            @update:modelValue="save('emspLogLevel')"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card variant="outlined" class="mb-4">
            <v-list-item-title class="ml-4 mt-2">
                <h2 class="d-flex align-center">
                    <v-icon start color="accent" size="x-small">mdi-file-tree-outline</v-icon>
                    Update CP structure
                </h2>
            </v-list-item-title>
            <v-card-text>
                <v-row class="ma-0 mb-4">
                    <span>
                        Gets
                        <v-chip size="small" variant="outlined"> LocationGroups </v-chip> ,
                        <v-chip size="small" variant="outlined"> Locations </v-chip> ,
                        <v-chip size="small" variant="outlined"> ChargePoints </v-chip> ,
                        <v-chip size="small" variant="outlined"> Evses </v-chip> and
                        <v-chip size="small" variant="outlined"> Connectors </v-chip>
                        from DTM and update ones saved in OCPI
                    </span>
                </v-row>
                <v-btn @click="updateOcpiDataFromDtm" :loading="updateDataLoading" text variant="outlined" color="accent">
                    <v-icon start>mdi-update</v-icon>
                    Run Update Process
                    <template v-slot:loader>
                        <v-icon start>mdi-loading mdi-spin</v-icon>
                        <span>Updating ...</span>
                    </template>
                </v-btn>
                <v-chip 
                    class="ml-4" 
                    variant="tonal"
                    color="info"
                    label
                >
                <v-icon class="mr-1" icon="mdi-information"/>
                This process may take a few minutes
                </v-chip>
            </v-card-text>
        </v-card>

        <v-card variant="outlined" class="mb-4">
            <v-list-item-title class="ml-4 mt-2">
                <h2 class="d-flex align-center">
                    <v-icon start color="accent" size="x-small">mdi-import</v-icon>
                    Evse status sync: DTM <v-icon color="accent" size="x-small">mdi-arrow-right-bold</v-icon> OCPI
                </h2>
            </v-list-item-title>
            <v-card-text>
                <v-row class="ma-0 mb-4">
                    <span>
                    Gets <v-chip size="small" variant="outlined">Evses</v-chip> from DTM and update its status
                    </span>
                </v-row>
                <v-btn @click="updateOcpiEvsesStatusFromDtm" :loading="updateEvsesStatusLoading" text variant="outlined" color="accent">
                    <v-icon start>mdi-update</v-icon>
                    Run Update Process
                    <template v-slot:loader>
                        <v-icon start>mdi-loading mdi-spin</v-icon>
                        <span>Updating ...</span>
                    </template>
                </v-btn>
                <span class="ml-4" v-if="updateEvsesSatatusResult">{{ updateEvsesSatatusResult }}</span>
            </v-card-text>
        </v-card>

        <v-card variant="outlined" class="mb-4">
            <v-list-item-title class="ml-4 mt-2">
                <h2 class="d-flex align-center">
                    <v-icon start color="accent" size="x-small">mdi-account-arrow-left</v-icon>
                    Evse status sync: Provider <v-icon color="accent" size="x-small">mdi-arrow-right-bold</v-icon> OCPI
                </h2>
            </v-list-item-title>

            <v-card-text>
                <v-row class="ma-0 mb-4">
                    <span> 
                        Obtains list of locations of a provider to update <v-chip size="small" variant="outlined">Evse status</v-chip> in OCPI.
                    </span>
                </v-row>
                <EvStatusFromProviderSyncTool class="mt-4 pa-2"/>
            </v-card-text>
        </v-card>

        <v-card variant="outlined">
            <v-list-item-title class="ml-4 mt-2">
                <h2 class="d-flex align-center">
                    <v-icon start color="accent" size="x-small">mdi-export</v-icon>
                    Evse status sync: OCPI <v-icon color="accent" size="x-small">mdi-arrow-right-bold</v-icon> Core
                </h2>
            </v-list-item-title>
            <v-card-text>
                <v-row class="ma-0 mb-4">
                    <span>
                        Synchronizes <v-chip size="small" variant="outlined">Evse status</v-chip> of external providers stored in OCPI to Core.
                    </span>
                </v-row>
                <EvStatusToCoreSyncTool class="mt-4 pa-2"/>
            </v-card-text>
        </v-card>
        
    </div>
    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"
import EvStatusFromProviderSyncTool from "@/components/settings/EvStatusFromProviderSyncTool"
import EvStatusToCoreSyncTool from "@/components/settings/EvStatusToCoreSyncTool"
import LogLevelSelect from "@/components/settings/LogLevelSelect"

export default {
    name: "providers",
    components: { 
        AppBar,
        EvStatusFromProviderSyncTool,
        EvStatusToCoreSyncTool,
        LogLevelSelect
    },
    inject: ['$api'],
    data() {
        return {
            loglevels: ['debug', 'info', 'warning', 'error', 'critical'],
            loading: false,

            settings: {},

            updateDataLoading: false,

            updateEvsesStatusLoading: false,
            updateEvsesSatatusResult: null,

        }
    },
    async mounted() {

        this.loading = true
        this.$api.get(`/config`)
            .then(resp => { this.settings = resp.data })
            .catch(this.showError)
            .then(_ => { this.loading = false })

    },
    methods: {
        save(key) {
            const keyValue = this.settings[key]

            this.$api.get(`/config/set/${key}/${keyValue}`)
                .then(_ => this.showSuccess(`${key} changed to ${keyValue} correctly`))
                .catch(this.showError)

        },
        updateOcpiDataFromDtm(){

            this.updateDataLoading = true
            this.$api.get('/wenea/update-data-from-dtm')
                .then(_ => this.showSuccess('Data updated successfully'))
                .catch(this.showError)
                .then(_ => { this.updateDataLoading = false })

        },
        updateOcpiEvsesStatusFromDtm(){

            this.updateEvsesSatatusResult = null
            this.updateEvsesStatusLoading = true
            this.$api.get('/wenea/update-evses-status-from-dtm')
                .then(resp => {
                    this.updateEvsesSatatusResult = `Updated ${resp.data} evses.`
                    this.showSuccess('Data updated successfully')
                })
                .catch(this.showError)
                .then(_ => { this.updateEvsesStatusLoading = false })

        },
    }
}
</script>