<template>
    <div v-if="componentType=='table'">
        {{ dtmSessionId }}
        <v-btn
            :href="getDTMUrlByEnv()" 
            icon="mdi-arrow-top-right" 
            variant="text" 
            size="x-small"
            class="mb-1"
            flat 
        />
    </div>

    <v-btn :href="getDTMUrlByEnv()" v-else>
        DTM Session {{ dtmSessionId }}
        <v-icon end>mdi-arrow-top-right</v-icon>
    </v-btn>
</template>
  
<script>
    export default {
    name: "dtmSessionButton",
    props: ["dtmSessionId", "componentType"],
    
    methods: {
      getDTMUrlByEnv() {
        let url = "";
        switch (process.env.VUE_APP_SUB_ENV) {
          case "DEV":
            url = `https://dtm-des.wenea.site/#/transaction/${this.dtmSessionId}`;
            break;
          case "PRE":
            url = `https://dtm-pre.wenea.site/#/transaction/${this.dtmSessionId}`;
            break;
          case "PRO":
            url = `https://dtm-pro.wenea.site/#/transaction/${this.dtmSessionId}`;
            break;
        }
        return url;
      },
    }
  }
</script>