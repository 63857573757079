<template>
    <span v-if="provider">
        <v-tooltip bottom v-if="role">
            <template v-slot:activator="{ props }">
                <v-chip 
                    v-bind="props" 
                    :size="type != 'title' ? 'small': 'default'"
                    variant="outlined" 
                    :class="role === 'cpo' ? 'bg-secondary-outlined-chip' : 'bg-accent-outlined-chip'"
                > 
                    <template v-slot:default>
                        <ProviderNameContent :provider="provider" :extra="extra" :type="type" />
                    </template>
                </v-chip>
            </template>
            <span v-if="role" class="text-uppercase">
                {{ role }}
            </span>
        </v-tooltip>

        <template v-else>
            <v-chip :size="type != 'title' ? 'small': 'default'" variant="outlined">
                <ProviderNameContent :provider="provider" :extra="extra" :type="type" />
            </v-chip>
        </template>
    </span>
</template>
  
<script>
    import ProviderNameContent from "@/components/provider/ProviderNameContent.vue";
    export default {
    name: "providerName",
    props: ["provider", "role", "extra", "type"],
    components: {
        ProviderNameContent,
    }
  }
</script>