<template>
  <div>
    <!-- Table filters -->
    <v-row class="filer-row mt-3">
        <v-col cols="12" md="2">
            <SearchTextField
                v-model="selectedSearch"
                :searchFunction="onFilter"
            />
        </v-col>
        <v-col cols="12" md="2">
            <v-autocomplete
            class="filter-field accent"
            v-model="selectedSenderProvider"
            :items="providers"
            label="Sender"
            clearable
            variant="outlined"
            hide-details
            >
                <template v-slot:item="{ props, item }">
                    <v-list-item
                        v-bind="props"
                        title
                    >
                        <ProviderSelectorTemplate :provider="item.raw" />
                    </v-list-item>
                </template>
            </v-autocomplete>
        </v-col>
        <v-col cols="12" md="2">
            <v-autocomplete
            class="filter-field accent"
            v-model="selectedReceiverProvider"
            :items="providers"
            label="Receiver"
            clearable
            variant="outlined"
            hide-details
            >
                <template v-slot:item="{ props, item }">
                    <v-list-item
                        v-bind="props"
                        title
                    >
                        <ProviderSelectorTemplate :provider="item.raw" />
                    </v-list-item>
                </template>
            </v-autocomplete>
        </v-col>
        <v-col cols="12" md="2">
          <v-autocomplete
            class="filter-field accent"
            v-model="selectedCountry"
            :items="countries"
            label="Country"
            clearable
            variant="outlined"
            hide-details
          />
        </v-col>
        <v-col cols="12" md="2" xl="1">
          <BooleanSelector
            class="filter-field accent" 
            v-model="selectedSentToProvider"
            label="Sent to provider"
            trueLabel="Sent"
            falseLabel="Not sent"
          />
        </v-col>

        <SearchButton
            :loading="loading"
            :searchFunction="onFilter"
        />

        <v-spacer />

        <v-btn 
            color="accent"
            :loading="loadingCSV"
            variant="outlined" 
            @click="createCSV"
            prepend-icon="mdi-file-download-outline"
        >
            CSV
        </v-btn>
    </v-row>

    <!-- Data table -->
    <v-data-table-server
      class="accent-table rounded-table"
      :headers="[
        { title: 'Id.', key: 'id', align: 'center' },
        { title: 'OCPI Id.', key: 'ocpi_id' },
        { title: 'CPO / EMSP', key: 'sender_receiver', align: 'center' },
        { title: 'Session', key: 'session' },
        { title: 'Energy', key: 'total_energy' },
        { title: 'Time', key: 'total_time' },
        { title: 'Total cost', key: 'total_cost' },
        { title: 'Applied tariff', key: 'applied_tariff' },
        { title: 'Sent', key: 'sent' },
        { title: 'Provider response', key: 'provider_response' },
        { title: 'Created at', key: 'created_at' },
        { key: 'actions' },
      ]"
      :items="cdrs"
      :loading="loading"
      :items-per-page="tableOptions.itemsPerPage"
      :items-length="pagination.serverItemsLength"
      :footer-props="{ 'items-per-page-options': pagination.itemsPerPageOptions }"
      density="compact"
      @update:options="this.updateTableOptions"
    >
      <template v-slot:item.sender_receiver="{ item }">
        <div class="d-flex align-center">
            <!-- Sender -->
            <v-chip v-if="item.sender_provider_id === 1" class="bg-secondary-chip text-capitalize" variant="flat" size="small">{{ item.sender_name }}</v-chip>
            <strong v-else>
                <ProviderName
                    :provider="item.senderProvider"
                    role="cpo"
                />
            </strong>
            <!-- Arrow -->
            <v-icon color="secondary">mdi-arrow-right</v-icon>
            <!-- Receiver -->
            <v-chip v-if="item.receiver_id === 1" class="bg-accent text-capitalize" variant="flat" size="small">{{ item.receiver_name }}</v-chip>
            <strong v-else>
                <ProviderName
                    :provider="item.receiverProvider"
                    role="emsp"
                />
            </strong>
        </div>
      </template>
      <template v-slot:item.session="{ item }">
        <v-btn v-if="item.charging_session_id"
          variant="text"
          :to="`/session/${item.charging_session_id}`" 
        >
          {{item.charging_session_id}}
        </v-btn>
      </template>
      <template v-slot:item.total_energy="{ item }">
        {{ item.total_energy }} kWh
      </template>
      <template v-slot:item.total_time="{ item }">
        {{ hoursToTimeTextFormat(item.total_time) }}
      </template>
      <template v-slot:item.total_cost="{ item }">
        <span v-if="item.total_cost"
          >{{ item.total_cost.excl_vat }}
          {{ item.currency }}</span
        >
        <span v-else>-</span>
      </template>
      <template v-slot:item.applied_tariff="{ item }">
        <v-btn v-if="item.tariff_id"
        variant="text"
          :to="`/tariffs/${item.tariff_id}`" 
        >
          <v-icon left>mdi-tag-text-outline</v-icon> {{item.tariff_id}}
        </v-btn>
      </template>
      <template v-slot:item.sent="{ item }" >
          <v-icon 
            v-if="item.sender_provider_id === 1"
            :color="item.sent_to_provider ? 'success' : 'error'"
            @click="checkNextVal(item)"
          >
          {{ item.sent_to_provider ? 'mdi-check-circle' : 'mdi-close-circle' }}
          </v-icon>
      </template>
      <template v-slot:item.provider_response="{ item }">
          <json-viewer v-if="item.provider_response"
              class="pa-2 text-left"
              :value="item.provider_response"
              :expand-depth="0"
              >
          </json-viewer>
      </template>
      <template v-slot:item.created_at="{ item }">
          {{ dateFullFormat(item.created_at) }}
      </template>
      <template v-slot:item.actions="{ item }">
          <v-tooltip bottom v-if="item.sender_provider_id === 1">
              <template v-slot:activator="{ props }">
                  <v-btn v-bind="props"
                    color="accent"
                    variant="text"
                    size="small"
                    @click.stop="sendToProvider(item.id)"
                    icon="mdi-send"
                  />
              </template>
              <span>Send CDR to provider</span>
            </v-tooltip>
      </template>
    </v-data-table-server>

    <InvoiceCdrDialog />
  </div>
</template>

<script>
import SearchTextField from "@/components/ui-components/SearchTextField.vue"
import BooleanSelector from "@/components/ui-components/BooleanSelector.vue"
import SearchButton from "@/components/ui-components/SearchButton.vue"
import ProviderSelectorTemplate from "@/components/provider/ProviderSelectorTemplate"
import ProviderName from "@/components/provider/ProviderName"
import Countries from "@/../../shared/resources/WorldCountryCodes_EN.json"
import { EventBus } from "@/event-bus"
import InvoiceCdrDialog from "@/components/cdr/InvoiceCdrDialog.vue"

export default {
  name: "cdrs",
  components: {
    SearchTextField,
    BooleanSelector,
    SearchButton,
    ProviderSelectorTemplate,
    ProviderName,
    InvoiceCdrDialog
  },
  props: {
    providerId: {
      type: Number,
    }
  },
  inject: ['$api'],
  data() {
    return {
      loading: false,
      loadingCSV: false,
      selectedSearch: null,
      selectedReceiverProvider: null,
      selectedSenderProvider: null,
      selectedCountry: null,
      selectedSentToProvider: null,
      cdrs: [],
      providers: [],

      pagination: {
        itemsPerPageOptions: [25, 50, 100, -1],
        serverItemsLength: 0
      },
      tableOptions: {
        itemsPerPage: 25,
        page: 1
      },
      initialLoad: true
};
  },
  mounted() {
    this.load()
  },
  watch: {
        //Necessary to update the filters when the user uses the browser back/forward button also to update filters when the user refresh the page
        '$route.query':{ 
            handler(params) {
                this.routeFilters(params)
                this.getCdrs()
            },
            immediate: true
        },
    },
    computed:{
        countries() { 
            return Countries.map(c => { 
                return { title: `${c.name} - ${c.alpha3}`, value: c.alpha3 } 
            }) 
        }
    },
  methods: {
    load() {
        this.$api.get("/providers")
            .then(res => {
                this.providers = res.data.map(p => { 
                    return { 
                        ...p, 
                        title: p.role ? `${p.country_code}-${p.party_id} ${p.company?.name || p.user.company?.name || p.user.username} (${p.role})` : `${p.country_code}-${p.party_id} ${p.company?.name || p.user.company?.name || p.user.username}`, 
                        value: p.id 
                    } 
                })
            })
            .catch(this.showError)
    },
    async getCdrs(paginatedResponse = true) {
      this.loading = true    
      let queryParams = ''

      if(paginatedResponse) { 
        queryParams = `offset=${(this.tableOptions.itemsPerPage * this.tableOptions.page) - this.tableOptions.itemsPerPage}` 
        if (this.tableOptions.itemsPerPage > 0) { queryParams += `&limit=${this.tableOptions.itemsPerPage}` }
      }

      if (this.selectedSearch && this.selectedSearch !== '') { queryParams += `&orfilter[id,charging_session_id,ocpi_id,total_energy]=${this.selectedSearch}` }

      if (this.selectedReceiverProvider) { queryParams += `&filter[receiver_provider_id]=${this.selectedReceiverProvider}` }
      if (this.selectedSenderProvider) { queryParams += `&filter[sender_provider_id]=${this.selectedSenderProvider}` }
      if (this.selectedCountry) { queryParams += `&location_country=${this.selectedCountry}` }
      if (this.selectedSentToProvider != null) { queryParams += `&filter[sent_to_provider]=${this.selectedSentToProvider}` }
      
      queryParams += '&orderBy[created_at]=desc'
      const getURL = `cdr?${queryParams}`

      await this.$api
        .get(getURL)
        .then((resp) => {
          this.cdrs = resp.data.map((cdr) => {
            return {
              ...cdr,
              sender_id: cdr.senderProvider?.id,
              sender_name: cdr.senderProvider?.user?.username,
              receiver_id: cdr.receiverProvider?.id,
              receiver_name: cdr.receiverProvider?.user?.username,
            };
          });
          this.pagination.serverItemsLength = Number(resp.headers['x-total-count'])
        })
        .catch(this.showError)
        .then(() => {
            this.loading = false
            this.initialLoad = false
        })
    },
    onFilter(){
        if(this.loading) return
        this.urlPush('search', this.selectedSearch)
        this.urlPush('receiver_provider', this.selectedReceiverProvider)
        this.urlPush('sender_provider', this.selectedSenderProvider)
        this.urlPush('country', this.selectedCountry)

        this.getCdrs()
    },
    updateTableOptions(args){
        this.tableOptions = args
        if(!this.initialLoad){
            this.getCdrs()
        }
    },
    sendToProvider(id) {
      this.$api
        .post(`send-cdr/${id}`)
        .then((resp) => { 
          this.getCdrs() 

          if (resp.data.success) {
            this.showSuccess('CDR has been sent successfully')
          } else {
            this.showError('CDR has not been sent correctly')
          }
        })
        .catch(this.showError)
    },
    checkNextVal(item){

      const confirmed = window.confirm('Are you sure you want to change the value of the field?')
      if(!confirmed){ return }

      const newValue = !item.sent_to_provider

      this.$api
        .patch(`cdr/${item.id}`, { sent_to_provider: newValue })
        .then(() => this.cdrs.map(cdr => {
          if(cdr.id == item.id) { cdr.sent_to_provider = newValue }
        }))
        .catch(this.showError)
    },
    async createCSV(){
        this.loadingCSV = true

        await this.getCdrs(false)
            .catch(this.showError)
            .finally(() => this.loadingCSV = false)   

        let content = '"Id";"OCPI Id";"Sender Id";"Sender";"Receiver Id";"Receiver";"Session Id";"Location";"Location Country";"Energy (kWh)";"Time (minutes)";"Total cost";"Tariff";"Sent";"Created at"\r\n'
        
        for( const row of this.cdrs ){
            content += `"${row.id}";"${row.ocpi_id}";"${row.sender_id}";"${row.sender_name} ${row.senderProvider?.country_code || ''}-${row.senderProvider?.party_id || ''}";"${row.receiver_id}";"${row.receiver_name} ${row.receiverProvider?.country_code || ''}-${row.receiverProvider?.party_id || ''}";"${row.charging_session_id}";"${row.location_snapshot?.name || ""}";"${row.location_snapshot?.country || ""}";"${row.total_energy}";"${row.total_time}";"${row.total_cost?.excl_vat}";"${row.tariff_snapshot?.ocpi_id || ""}";"${row.sent_to_provider}";"${row.created_at}"\r\n`
        }

        this.downloadBlob(content, `cdrs.csv`, "text/csv")
        this.loading = false

        this.showDialog(this.cdrs.map(cdr => cdr.id))
    },
    showDialog(cdr_ids){
        EventBus().emit(
            "dialog-invoice-cdr:open",
            { cdrs: cdr_ids }
        )
    },
  }
};
</script>

<style scoped>
  :deep(.jv-code){
    padding: 0!important;
  }
  :deep(.jv-container.jv-light){
    background: transparent !important;
  }
</style>