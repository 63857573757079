<template>
    <v-dialog v-model="show" persistent max-width="1180px">
        <v-card>
            <v-card-title class="bg-accent white--text">
                <span class="headline">Sending locations to provider</span>
            </v-card-title>
            <v-card-text class="pa-4">
                <v-layout justify-center align-center v-if="loading">
                    <v-row class="align-center justify-center">
                        <v-progress-circular 
                            class="my-4 mx-4"
                            indeterminate
                            color="accent"
                            :size="40"
                            :width="7"
                        ></v-progress-circular>
                        <p>Sending locations to provider...</p>
                    </v-row>
                </v-layout>

                <div v-else>
                    <v-data-table
                        :headers="[
                            { title: 'Id.', key: 'id'},
                            { title: 'OCPI Id.', key: 'ocpi_id' },
                            { title: 'Sent successfully', key: 'success' },
                            { title: 'Message', key: 'message' },
                        ]"
                        :items="sentLocations"
                        :group-by="[{ key: 'success' }]"
                        density="compact"                      
                    >
                        <template v-slot:group-header="{ item, columns, toggleGroup, isGroupOpen }">
                            <td :colspan="columns.length">
                                <v-btn @click="toggleGroup(item)" icon flat>
                                    <v-icon v-if="isGroupOpen(item)">mdi-minus</v-icon>
                                    <v-icon v-else>mdi-plus</v-icon>
                                </v-btn>
                                <span class="mx-5 font-weight-bold">{{ item ? 'Locations sent successfully' : 'Locations NOT sent successfully' }}</span>
                            </td>
                        </template>
                    </v-data-table>
                </div>

            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn @click="show = false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { EventBus } from "@/event-bus"

export default {
    inject: ['$api'],
    data() {
        return {
            show: false,
            loading: false,
            sentLocations: [],
        }
    },
    watch: {
        show( show ) {
            if ( !show ) {
                this.sentLocations = []
                EventBus().emit("dialog-send-location-group:close")
            }
        }
    },
    created() {
        var vm = this;
        EventBus().on("dialog-send-location-group:open", async function( args ) {
            if( !args.group_id ){
                vm.showError("Data error, no group_id")
                return false
            }

            if( !args.provider_id ){
                vm.showError("Data error, no provider_id")
                return false
            }
            
            vm.sendLocations(args.provider_id, args.group_id)

            vm.show = true
        })
    },
    methods: {
        async sendLocations(provider_id, group_id){
            this.loading = true

            this.$api
                .put(`provider/${provider_id}/location-groups/${group_id}/send`)
                .then((resp) => {
                    this.sentLocations = resp.data
                })
                .catch(this.showError)
                .then((_) => { this.loading = false })
        }
    }
}
</script>