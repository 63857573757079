<template>
    <v-dialog v-model="show" scrollable :max-width="600">
        <v-card>
                <v-card-title class="pa-0">
                    <v-toolbar color="accent white--text elevation-0">
                        <v-toolbar-title class="headline">{{ title }}</v-toolbar-title>
                    </v-toolbar>
                </v-card-title>

            <v-card-text>
                <v-row class="filer-row">
                    <v-col cols="12" md="6">
                        <SearchTextField
                            v-model="selectedSearch"
                            :searchFunction="onFilter"
                        />
                    </v-col>

                    <SearchButton
                        :searchFunction="onFilter"
                    />

                    <v-spacer />

                    <v-btn 
                        color="accent"
                        :loading="loadingCSV"
                        variant="outlined" 
                        @click="createCSV"
                        prepend-icon="mdi-file-download-outline"
                    >
                        CSV
                    </v-btn>
                </v-row>
                <v-row>
                    <v-data-table
                        :headers="[
                            {title: 'Country Code', key: 'country_code'},
                            {title: 'Party Id.', key: 'party_id'},
                            {title: 'Role', key: 'role'},
                            {title: 'Status', key: 'status'}
                        ]"
                        :items="json"
                        disable-sort
                        :search="tableSearch"
                        class="accent-table rounded-table px-3"
                        density="compact"
                    >
                        <template v-slot:item.role="{ item }">
                            <v-chip
                                color="accent"
                                variant="outlined"
                                size="small"
                            >
                                {{ item.role }}
                            </v-chip>
                        </template>

                        <template v-slot:item.status="{ item }">
                            <StatusChip v-if="item.status" :status="item.status" />
                        </template>

                    </v-data-table>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-spacer />
                <v-btn @click="show = false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { EventBus } from "@/event-bus"
import SearchTextField from "@/components/ui-components/SearchTextField.vue"
import SearchButton from "@/components/ui-components/SearchButton.vue"
import StatusChip from "@/components/connection/StatusChip"

export default {
    components: {
        SearchTextField,
        SearchButton,
        StatusChip
    },
    data() {
        return {
            show: false,
            title: null,
            json: null,
            selectedSearch: null,
            tableSearch: null,
            loadingCSV: null,
        }
    },
    watch: {
        show( show ) {
            if ( !show ) {
                this.title = null
                EventBus().emit("dialog-hub-client-info:close")
            }
        }
    },
    created() {
        var vm = this;
        EventBus().on("dialog-hub-client-info:open", async function( args ) {
            
            if( !args.json ){
                vm.showError("Data error, no json")
                return false
            }

            vm.title = args.title
            vm.json = args.json
            vm.show = true

        })
    },
    methods: {
        onFilter(){
            this.tableSearch = this.selectedSearch
        },
        async createCSV(){
            this.loadingCSV = true

            let content = '"Country Code","Party Id.","Role","Status"\r\n'
            
            for( const row of this.json ){
                content += `"${row.country_code}","${row.party_id}","${row.role}","${row.status}"\r\n`
            }

            this.downloadBlob(content, `hub-client-info.csv`, "text/csv")
            this.loadingCSV = false
        }
    }
}
</script>