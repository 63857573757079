<template>
    <v-select 
        v-model="value" 
        :items="[true, false]"
        :label="label" 
        variant="outlined" 
        clearable 
        hide-details
    >
        <template v-slot:selection="{ item }">
            <span v-if="item.value" style="white-space: nowrap;">
                <v-icon icon="mdi-check-circle" color="success" size="small" /> {{ trueLabel || 'True' }}
            </span>
            <span v-else style="white-space: nowrap;">
                <v-icon icon="mdi-close-circle" color="error" size="small" /> {{ falseLabel || 'False' }}
            </span>
        </template>
        <template v-slot:item="{ props, item }">
            <v-list-item v-bind="props" title>
                <span v-if="item.value">
                    <v-icon icon="mdi-check-circle" color="success" size="small" /> {{ trueLabel || 'True' }}
                </span>
                <span v-else>
                    <v-icon icon="mdi-close-circle" color="error" size="small" /> {{ falseLabel || 'False' }}
                </span>
            </v-list-item>
        </template>
    </v-select>
</template>

<script>
export default {
    props: {
        label: String,
        trueLabel: String,
        falseLabel: String,
    },
}
</script>