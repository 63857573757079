<template>
    <v-select 
        class="filter-field accent" 
        :value="value" 
        :items="loglevels" 
        :label="label"
        :disabled="disabled"
        variant="outlined" 
        hide-details
    >
        <template v-slot:selection="{ item }">
            <v-chip 
                :color="levelColor(item.raw)" 
                variant="outlined"
            >
                {{ item.raw.toUpperCase() }}
            </v-chip>
        </template>
        <template v-slot:item="{ props, item }">
            <v-list-item v-bind="props" v-if="item" title>
                <v-chip 
                    :color="levelColor(item.raw)" 
                    variant="outlined"
                >
                    {{ item.raw.toUpperCase() }}
                </v-chip>
            </v-list-item>
        </template>
    </v-select>
</template>

<script>
export default {
    props: {
        value: {
            type: String
        },
        label: {
            type: String
        },
        isAdonis6: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        }
    },
    computed: {
        loglevels() {
            if (this.isAdonis6) {
                return ['debug', 'info', 'warn', 'error', 'fatal']
            }

            return ['debug', 'info', 'warning', 'error', 'critical']
        }
    },
    methods: {
        levelColor(level) {
            switch (level) {
                case 'debug':
                    return 'blue'
                case 'info':
                    return 'green'
                case 'warning':
                case 'warn':
                    return 'orange'
                case 'error':
                    return 'red'
                case 'critical':
                case 'fatal':
                    return 'red-darken-4'
                default:
                   return 'grey'
            }
        }
    }
}
</script>