<template>
    <v-container fluid>

        <AppBar title="Locations" icon="mdi-map-marker" />

        <!-- Table filters -->
        <v-row class="filer-row">
            <v-col cols="12" md="2">
                <SearchTextField
                    v-model="selectedSearch"
                    :searchFunction="onFilter"
                />
            </v-col>
            <v-col cols="12" md="2">
                <v-autocomplete
                    class="filter-field accent"
                    v-model="selectedState"
                    :items="states"
                    label="State"
                    variant="outlined"
                    clearable
                    hide-details
                ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="2">
                <v-autocomplete
                    class="filter-field accent"
                    v-model="selectedProvider"
                    :items="providers"
                    label="Provider"
                    variant="outlined"
                    clearable
                    hide-details
                >
                    <template v-slot:item="{ props, item }">
                        <v-list-item
                            v-bind="props"
                            title
                        >
                            <ProviderSelectorTemplate :provider="item.raw" />
                        </v-list-item>
                    </template>
                </v-autocomplete>
            </v-col>

            <v-col cols="12" md="2" xl="1">
                <BooleanSelector
                    class="filter-field accent"
                    v-model="selectedPublish"
                    label="OCPI Publish"
                    trueLabel="Published"
                    falseLabel="Not published"
                />
            </v-col>

            <v-col cols="12" md="2" xl="1">
                <BooleanSelector
                    class="filter-field accent"
                    v-model="selectedPublishedToCore"
                    label="APP/Core published"
                    trueLabel="Published"
                    falseLabel="Not published"
                />
            </v-col>

            <SearchButton
                :loading="tableLoading"
                :searchFunction="onFilter"
            />

            <v-spacer />

            <v-btn 
                color="accent"
                :loading="loadingCSV"
                variant="outlined" 
                @click="createCSV"
                prepend-icon="mdi-file-download-outline"
            >
                CSV
            </v-btn>
        </v-row>

        <!-- Data table -->
        <LocationsTable 
            :locations="locations" 
            :tableLoading="tableLoading"
            :options="tableOptions"
            :pagination="pagination"
            :updateTableOptions="this.updateTableOptions"
        />

    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"
import SearchTextField from "@/components/ui-components/SearchTextField.vue"
import SearchButton from "@/components/ui-components/SearchButton.vue"
import BooleanSelector from "@/components/ui-components/BooleanSelector.vue"
import ProviderSelectorTemplate from "@/components/provider/ProviderSelectorTemplate"
import LocationsTable from "@/components/location/LocationsTable"
import _ from "lodash"

export default {
    name: "wenea",
    components: {
        AppBar,
        SearchTextField,
        BooleanSelector,
        SearchButton,
        ProviderSelectorTemplate,
        LocationsTable,
    },
    inject: ['$api'],
    data() {
        return {
            selectedSearch: undefined,
            tableLoading: false,
            selectedState: undefined,
            selectedProvider: null,
            selectedPublish: null,
            selectedPublishedToCore: null,
            states: [],
            providers: [],
            locations: [],
            pagination: {
                itemsPerPageOptions: [25, 50, 100, -1],
                serverItemsLength: 0
            },
            tableOptions: {
                itemsPerPage: 25,
                page: 1,
            },
            loadingCSV: false,
            initialLoad: true
        }
    },
    mounted(){
        this.load()
    },
    watch: {
        //Necessary to update the filters when the user uses the browser back/forward button also to update filters when the user refresh the page
        '$route.query':{ 
            handler(params) {
                this.routeFilters(params)
                this.loadLocations()
            },
            immediate: true
        },
    },
    methods: {
        load() {
            this.$api.get(`locations/states`)
                .then(resp => { 
                    this.states = resp.data.filter(item => item).map(item => { return { title: item, value: item } }) 
                })
                .catch(this.showError)  
                
            this.$api.get("/providers")
                .then(res => {
                    this.providers = res.data
                        .filter(p => p.role.includes('CPO'))
                        .map(p => { 
                            return { 
                                ...p, 
                                title: p.role ? `${p.country_code}-${p.party_id} ${p.company?.name || p.user.company?.name || p.user.username} (${p.role})` : `${p.country_code}-${p.party_id} ${p.company?.name || p.user.company?.name || p.user.username}`, 
                                value: p.id 
                            } 
                        })
                    })
                .catch(this.showError)
        },
        async loadLocations(){
            this.tableLoading = true
            this.getLocations(true)
                .then(resp => {
                    this.pagination.serverItemsLength = Number(resp.headers['x-total-count'])
                    this.locations = resp.data
                })
                .catch(this.showError)
                .finally(() => {
                    this.tableLoading = false
                    this.initialLoad = false
                })
        },
        async getLocations(paginatedResponse){

            let queryParams = ''
            if(paginatedResponse) { 
                queryParams += `offset=${(this.tableOptions.itemsPerPage * this.tableOptions.page) - this.tableOptions.itemsPerPage}` 
                if (this.tableOptions.itemsPerPage > 0) { queryParams += `&limit=${this.tableOptions.itemsPerPage}` }
            }
            if (this.selectedSearch && this.selectedSearch !== '') { queryParams += `&orfilter[id,name,country_code,party_id]=${this.selectedSearch}` }
            queryParams += '&orderBy[name]=asc'
            if (this.selectedState) { queryParams += `&filter[state]=${this.selectedState}` }
            if (this.selectedProvider) { queryParams += `&filter[provider_id]=${this.selectedProvider}` }
            if (this.selectedPublish != null) { queryParams += `&filter[publish]=${this.selectedPublish}` }
            if (this.selectedPublishedToCore != null) { queryParams += `&published_to_core=${this.selectedPublishedToCore}` }

            return await this.$api.get(`locations?${queryParams}`)
                .then(resp => {
                    if( Array.isArray(resp.data) ){
                        resp.data.map(loc => {
                            loc.chargepoints = loc.chargepoints.map(cp => { return { reference_name: cp.reference_name, id: cp.id } })
                        })
                    }
                    return resp
                })
        },
        updateTableOptions(args){
            this.tableOptions = args
            if(!this.initialLoad){
                this.loadLocations()
            }
        },
        onFilter(){
            if(this.tableLoading) return
            this.urlPush('search', this.selectedSearch)
            this.urlPush('state', this.selectedState)
            this.urlPush('provider', this.selectedProvider)
            this.urlPush('showPublishFalse', this.selectedPublish)
            this.urlPush('publishedToCore', this.selectedPublishedToCore)

            this.loadLocations()
        },
        async createCSV(){
            this.loadingCSV = true
            const locations = await this.getLocations(false)
                .then(resp => resp.data)
                .catch(this.showError)
                .finally(() => this.loadingCSV = false)
            
            locations.map(loc => loc.chargepoints_str = loc.chargepoints.map(x => x.reference_name).join(", ") )

            let content = '"Id";"Name";"Provider";"Publish";"Charge points";"Country";"Postal Code";"City";"Address";"Coordinates";"Created";"Updated";\r\n'
            
            for( const row of locations ){
                content += `"${row.id}";"${row.name}";"${row.country_code}-${row.party_id}";"${row.publish}";"${row.chargepoints_str}";"${row.country}";"${row.postal_code}";"${row.city}";"${row.address}";"lat: ${row.coordinates.latitude}, lng: ${row.coordinates.longitude}";"${row.created_at}";"${row.updated_at}";\r\n`
            }

            this.downloadBlob(content, `locations.csv`, "text/csv")
            this.tableLoading = false
        }
    }
};
</script>
