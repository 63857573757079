<template>
    <div :style="getClass()">
        <v-icon size="small" color="black" v-if="extra !== 'no-icon'">mdi-earth</v-icon>
        <span class="text-uppercase" :style="getClass()" v-if="extra === 'applicable_provider'">
            {{ provider.country_code }}-{{ provider.party_id }}
        </span>
        <span class="text-uppercase" :style="getClass()" v-else-if="extra === 'applicable_user'">
            {{ provider.username }}
        </span>
        <template v-else>
            <span v-if="provider.company">
                {{ provider.company.name }}
            </span>
            <span v-else-if="provider.user && provider.user.company">
                {{ provider.user.company.name }}
            </span>
            <span v-else-if="provider.user">
                {{ provider.user.username }}
            </span>
            <span :style="getClass()" class="ml-1">
                {{ country_party }}
            </span>
        </template>
        <v-btn 
            v-if="extra !== 'no-arrow'"
            variant="plain"
            size="x-small"
            :to="getURL()"
        >
            <v-icon>mdi-arrow-top-right</v-icon>
        </v-btn>
    </div>
</template>
  
<script>
export default {
    name: "providerName",
    props: ["provider", "role", "extra", "type"],
    computed: {
        country_party(){
            if( this.provider?.party_id ){ 
                return this.provider?.country_code + "-" + this.provider?.party_id
            } else {
                return this.provider?.country_code
            }
        }
    },
    methods: {
        getURL() {
            switch (this.extra) {
                case "applicable_user":
                    return `/connection/${this.provider.applicable_user}`;
                default:
                    return `/provider/${this.provider.id}`;
            }
        },
        getClass() {
            switch(this.type){
                case "title":
                    return "font-size: 0.95rem; font-weight: 500;";
                default:
                    return "font-size: 0.85rem; font-weight: 500;";
            }
        }
    }
}
</script>